<template>
  <div id="jci-trainers-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Status</h6>
              <h6 class="text-dark">
                <v-chip :color="row.StatusColor" draggable dark>{{
                  row.StatusTxt
                }}</v-chip>
              </h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Status Description</h6>
              <h6 class="text-dark">{{ row.StatusDescription }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Year</h6>
              <h6 class="text-dark">{{ row.LeadYearName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Connect Date</h6>
              <h6 class="text-dark">{{ row.LeadDateTxt }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="6" md="6">
              <h6>Description</h6>
              <h6 class="text-dark">{{ row.LeadDescription }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Business Category</h6>
              <h6 class="text-dark">{{ row.BusinessCategoryName }}</h6>
            </v-col>
          </v-row>
          <hr />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text">Connect From</h5>
            </v-col>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <div v-if="row.FromMemberPhotoPath != ''">
                <img
                  style="padding: 5px"
                  width="100"
                  height="100"
                  :src="row.FromMemberPhotoPath"
                  alt=""
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Table</h6>
              <h6 class="text-dark">{{ row.FromTableName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Member Name</h6>
              <h6 class="text-dark">{{ row.FromMemberName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Mobile No</h6>
              <h6 class="text-dark">{{ row.FromMemberMobileNo }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Email Id</h6>
              <h6 class="text-dark">{{ row.FromMemberEmailId }}</h6>
            </v-col>
          </v-row>
          <hr />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text">Connect To</h5>
            </v-col>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <div v-if="row.ToMemberPhotoPath != ''">
                <img
                  style="padding: 5px"
                  width="100"
                  height="100"
                  :src="row.ToMemberPhotoPath"
                  alt=""
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Table</h6>
              <h6 class="text-dark">{{ row.ToTableName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Member Name</h6>
              <h6 class="text-dark">{{ row.ToMemberName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Mobile No</h6>
              <h6 class="text-dark">{{ row.ToMemberMobileNo }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Email Id</h6>
              <h6 class="text-dark">{{ row.ToMemberEmailId }}</h6>
            </v-col>
          </v-row>
          <hr />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="blue--text">Conect For</h5>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Customer Type</h6>
              <h6 class="text-dark">{{ row.CustomerTypeTxt }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Customer Name</h6>
              <h6 class="text-dark">{{ row.CustomerName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Mobile No</h6>
              <h6 class="text-dark">{{ row.CustomerMobileNo }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Email Id</h6>
              <h6 class="text-dark">{{ row.CustomerEmailId }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Email Id</h6>
              <h6 class="text-dark">{{ row.CustomerEmailId }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>Adress</h6>
              <h6 class="text-dark">{{ row.CustomerAddress }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>State</h6>
              <h6 class="text-dark">{{ row.CustomerStateName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>District</h6>
              <h6 class="text-dark">{{ row.CustomerDistrictName }}</h6>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h6>City</h6>
              <h6 class="text-dark">{{ row.CustomerCityName }}</h6>
            </v-col>
          </v-row>
          <div v-if="row.StatusId == 1">
            <hr />
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h5 class="blue--text" align="center">G-Note Details</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Date</h6>
                <h6 class="text-dark">{{ row.SaleDateTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Amount</h6>
                <h6 class="text-dark">{{ row.SaleAmountTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Business Type</h6>
                <h6 class="text-dark">{{ row.SaleBusinessTypeTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Payment Type</h6>
                <h6 class="text-dark">{{ row.SalePaymentTypeTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" lg="6" md="6">
                <h6>Description</h6>
                <h6 class="text-dark">{{ row.SaleDescription }}</h6>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="3"
                md="3"
                v-if="row.SalesInvoicePath != ''"
              >
                <h6>Invoice</h6>
                <v-btn
                  :href="row.SalesInvoicePath"
                  target="_blank"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark color="green" medium> mdi-download </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          color="brown"
          elevation="30"
          shaped
          tile
          medium
          class="font-size-h6 px-6 py-4 my-3 mr-3 white--text"
        >
          Close
        </v-btn>
        <!-- <v-spacer></v-spacer> -->
      </v-card-actions>
    </v-card>
  </div>
</template>
      
      <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      Width: 500,

      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      ResultFlag: false,
      RecordFlag: false,
      LoadingFlag: false,
      row: {},
      PaymentDetails: {},
      selected: [],

      Description: "",

      ApprovedDate: new Date().toISOString().slice(0, 10),
      menu1: false,

      ApproveLoadingFlag: false,
      ApproveRejectLoadingFlag: false,
      ConfirmationLoadingFlag: false,
      ConfirmationRejectLoadingFlag: false,

      dialog: false,
      dialog1: false,

      tableColumns: [],
      tableOptions: [],
      tableData: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.row = recordData;
      console.log(this.row.LeadYearName);
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
    <style lang="scss">
</style>